"use client";

import type { NewsletterModel } from "@/app/(sites)/_api/newsletter/newsletter.types";
import { NewsletterSignUp } from "@/components/Newsletter/NewsletterSignUp/NewsletterSignUp.component";
import { useNewsletterSignup } from "@/components/Newsletter/NewsletterSignUp/use-newsletter-signup.hook";
import { NewsletterSignUpFailedBox } from "@/components/Newsletter/NewsletterSignUpFailedBox/NewsletterSignUpFailedBox.component";
import { NewsletterSignUpForm } from "@/components/Newsletter/NewsletterSignUpForm/NewsletterSignUpForm.component";
import { NewsletterSignUpSuccessBox } from "@/components/Newsletter/NewsletterSignUpSuccessBox/NewsletterSignUpSuccessBox.component";
import { SwitchFetchable } from "@/components/SwitchFetchable/SwitchFetchable.component";
import type { ConversionEvent } from "@/components/Tracking/Kilkaya/kilkaya.types";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending } from "@/services/utilities/fetchable";
import clsx from "clsx";
import { MonitorNewsletterImage } from "./MonitorNewsletterImage.component";
type MonitorNewsletterSignUpProps = {
  readonly className?: string;
  readonly conversionEventTags?: ConversionEvent;
  readonly initialSubmissionState?: Fetchable;
  readonly model: NewsletterModel;
};
export function MonitorNewsletterSignUp({
  className,
  conversionEventTags,
  initialSubmissionState = initial(),
  model
}: MonitorNewsletterSignUpProps) {
  const {
    reset,
    submissionState: submission,
    submit
  } = useNewsletterSignup(initialSubmissionState);
  return <NewsletterSignUp className={clsx("bg-primary text-white", className)} data-sentry-element="NewsletterSignUp" data-sentry-component="MonitorNewsletterSignUp" data-sentry-source-file="MonitorNewsletterSignUp.component.tsx">
      <div className="col-span-full xl:col-span-7">
        <SwitchFetchable fetchable={submission} renderDefault={() => <NewsletterSignUpForm isPending={isPending(submission)} model={model} onSubmit={submit} renderImage={() => <MonitorNewsletterImage />} variant="accent" />} renderFailed={() => <NewsletterSignUpFailedBox reset={reset} variant="accent" />} renderSucceeded={() => <NewsletterSignUpSuccessBox conversionEvent={conversionEventTags} />} data-sentry-element="SwitchFetchable" data-sentry-source-file="MonitorNewsletterSignUp.component.tsx" />
      </div>

      <div className="relative hidden xl:col-span-5 xl:block">
        <MonitorNewsletterImage data-sentry-element="MonitorNewsletterImage" data-sentry-source-file="MonitorNewsletterSignUp.component.tsx" />
      </div>
    </NewsletterSignUp>;
}