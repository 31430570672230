"use client";

import { useSite } from "@/contexts/site/site.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import NextImage from "next/image";
import { useState } from "react";
export function MonitorNewsletterImage() {
  const {
    id: siteId
  } = useSite();
  const [imgSource, setImgSource] = useState(`/images/newsletter/${siteId}.png`);
  return <NextImage alt="" className="select-none object-contain pt-1 sm:pr-2 sm:pt-0" draggable={false} fill={true} onError={() => {
    setImgSource("/images/newsletter/newsletter-sign-up-background.png");
  }} sizes={`(max-width: ${tailwindScreenSize.md}) 300px, 450px`} src={imgSource} data-sentry-element="NextImage" data-sentry-component="MonitorNewsletterImage" data-sentry-source-file="MonitorNewsletterImage.component.tsx" />;
}